module.exports = [{
      plugin: require('C:/R/MindBuilders/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/R/MindBuilders/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('C:/R/MindBuilders/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
